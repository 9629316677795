import React, { useEffect, useState } from 'react';

import { Card, CardHeader, CardBody, CardFooter, Typography, Tooltip } from "@material-tailwind/react";

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';




export function ProfileTeam() {
    const [teamMembers, setTeamMembers] = useState([]);

    useEffect(() => {
        const db = firebase.firestore();

        db.collection('staff')
            .where('isConseil', '==', false)
            .get()
            .then(async (querySnapshot) => {
                const data = await Promise.all(querySnapshot.docs.map(async (doc) => {
                    const event = doc.data();
                    const storageRef = firebase.storage().ref();
                    try {
                        const imgRef = storageRef.child(event.img);
                        const imgUrl = await imgRef.getDownloadURL();
                        return {
                            ...event,
                            img: imgUrl,
                        };
                    } catch (error) {
                        console.error('Error fetching image URL:', error);
                        return null;
                    }
                }));
                const filteredData = data.filter((item) => item !== null);
                setTeamMembers(filteredData);
            })
            .catch((error) => {
                console.error('Error retrieving events:', error);
            });
    }, []);

    return (
        <div>
            <Typography variant="h3" color="blue-gray" className="mb-2" style={{ paddingTop: "50px" }}>Les membres actifs</Typography>
            <hr style={{ paddingTop: "5px", paddingBottom: "5px" }} />
            <div class="grid gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {teamMembers.map((member) => (
                    <div key={member.id}>
                        <Card className="w-96">
                            <div>
                                <CardHeader floated={false} className="h-80">
                                    <img src={member.img} alt="" className='w-full'/>
                                </CardHeader>
                                <CardBody className="text-center">
                                    <Typography variant="h4" color="blue-gray" className="mb-2">
                                        {member.fullName}
                                    </Typography>
                                    <Typography color="blue-gray" className="font-medium" textGradient>
                                        {member.title}
                                    </Typography>
                                </CardBody>
                            </div>
                            <CardFooter className="flex justify-center gap-7 pt-2">
                                {member.instagram && (
                                    <Tooltip content="Instagram">
                                        <Typography as="a" href={"https://instagram.com/" + member.instagram} variant="lead" color="purple" textGradient>
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="35" height="35" viewBox="0 0 48 48">
                                                <radialGradient id="yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1" cx="19.38" cy="42.035" r="44.899" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fd5"></stop><stop offset=".328" stop-color="#ff543f"></stop><stop offset=".348" stop-color="#fc5245"></stop><stop offset=".504" stop-color="#e64771"></stop><stop offset=".643" stop-color="#d53e91"></stop><stop offset=".761" stop-color="#cc39a4"></stop><stop offset=".841" stop-color="#c837ab"></stop></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path><radialGradient id="yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2" cx="11.786" cy="5.54" r="29.813" gradientTransform="matrix(1 0 0 .6663 0 1.849)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#4168c9"></stop><stop offset=".999" stop-color="#4168c9" stop-opacity="0"></stop></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path><path fill="#fff" d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5	s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z"></path><circle cx="31.5" cy="16.5" r="1.5" fill="#fff"></circle><path fill="#fff" d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12	C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z"></path>
                                            </svg>
                                        </Typography>
                                    </Tooltip>
                                )}
                                {member.linkedin && (
                                    <Tooltip content="LinkedIn">
                                        <Typography as="a" href={"https://linkedin.com/in/" + member.linkedin} variant="lead" color="blue" textGradient>
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="35" height="35" viewBox="0 0 48 48">
                                                <path fill="#0288D1" d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"></path><path fill="#FFF" d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"></path>
                                            </svg>
                                        </Typography>
                                    </Tooltip>
                                )}
                                {member.github && (
                                    <Tooltip content="GitHub">
                                        <Typography as="a" href={"https://github.com/" + member.github} variant="lead" color="blue" textGradient>
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="35" height="35" viewBox="0 0 24 24">
                                                <path d="M10.9,2.1c-4.6,0.5-8.3,4.2-8.8,8.7c-0.5,4.7,2.2,8.9,6.3,10.5C8.7,21.4,9,21.2,9,20.8v-1.6c0,0-0.4,0.1-0.9,0.1 c-1.4,0-2-1.2-2.1-1.9c-0.1-0.4-0.3-0.7-0.6-1C5.1,16.3,5,16.3,5,16.2C5,16,5.3,16,5.4,16c0.6,0,1.1,0.7,1.3,1c0.5,0.8,1.1,1,1.4,1 c0.4,0,0.7-0.1,0.9-0.2c0.1-0.7,0.4-1.4,1-1.8c-2.3-0.5-4-1.8-4-4c0-1.1,0.5-2.2,1.2-3C7.1,8.8,7,8.3,7,7.6c0-0.4,0-0.9,0.2-1.3 C7.2,6.1,7.4,6,7.5,6c0,0,0.1,0,0.1,0C8.1,6.1,9.1,6.4,10,7.3C10.6,7.1,11.3,7,12,7s1.4,0.1,2,0.3c0.9-0.9,2-1.2,2.5-1.3 c0,0,0.1,0,0.1,0c0.2,0,0.3,0.1,0.4,0.3C17,6.7,17,7.2,17,7.6c0,0.8-0.1,1.2-0.2,1.4c0.7,0.8,1.2,1.8,1.2,3c0,2.2-1.7,3.5-4,4 c0.6,0.5,1,1.4,1,2.3v2.6c0,0.3,0.3,0.6,0.7,0.5c3.7-1.5,6.3-5.1,6.3-9.3C22,6.1,16.9,1.4,10.9,2.1z"></path>
                                            </svg>
                                        </Typography>
                                    </Tooltip>
                                )}
                            </CardFooter>
                        </Card>
                    </div>
                ))}
            </div>
        </div>
    );
}