import React from 'react';
import { MentionLegales } from './components/MentionLegales';



function Legal () {
    return (
        <div>
            <MentionLegales />
        </div>
    )
}

export default Legal;