import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';

import { Avatar, Card, Button, Typography, CardHeader, CardBody } from '@material-tailwind/react';



const NewsPage = () => {
  const { customLink } = useParams();
  const [news, setNews] = useState(null);
  const [author, setAuthor] = useState(null);

  useEffect(() => {
    const firestore = firebase.firestore();
    const storage = firebase.storage();

    const newsRef = firestore.collection('news');
    newsRef
      .where('link', '==', customLink)
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          const newsData = querySnapshot.docs[0].data();
          setNews(newsData);
          const authorId = newsData.authorId;
          const authorRef = firestore.collection('staff');
          authorRef
            .where(firebase.firestore.FieldPath.documentId(), '==', authorId)
            .get()
            .then(async (authorQuerySnapshot) => {
              if (!authorQuerySnapshot.empty) {
                const authorData = authorQuerySnapshot.docs[0].data();
                const imgRef = storage.ref().child(authorData.img);
                const imgUrl = await imgRef.getDownloadURL();
                authorData.avatar = imgUrl;
                setAuthor(authorData);
              } else {
                console.log('No matching documents found in staff collection.');
              }
            })
            .catch((error) => {
              console.error('Error fetching author:', error);
            });
        } else {
          console.log('No matching documents found in news collection.');
        }
      })
      .catch((error) => {
        console.error('Error fetching news:', error);
      });
  }, [customLink]);


  return (
    <div>
      {news ? (
        <div style={{
          paddingLeft: "10%",
          paddingRight: "10%",
          paddingTop: "100px"
        }}>
          <div style={{ textAlign: "center" }}>
            <Typography variant="h2">{news.title}</Typography>
          </div>
          <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-4" style={{ paddingTop: "50px" }}>
            <div className="">
              {author ? (
                <Card color="transparent" shadow={false} className="w-full max-w-[26rem]">
                  <div className="flex items-center gap-4 mb-6 p-0">
                    <Typography variant="body1" color="blue-gray">
                      Publié le {news.eventDate && new Date(news.eventDate.toDate()).toLocaleDateString("fr-FR", {
                                        day: "numeric",
                                        month: "long",
                                        year: "numeric",
                                    })} par
                    </Typography>
                  </div>
                  <CardHeader
                    color="transparent"
                    floated={false}
                    shadow={false}
                    className="mx-0 flex items-center gap-4 pt-0 pb-8"
                  >
                    <Avatar
                      size="lg"
                      variant="circular"
                      src={author.avatar}
                      alt={author.fullName}
                    />
                    <div className="flex w-full flex-col gap-0.5">
                      <div className="flex items-center justify-between">
                        <Typography variant="h5" color="blue-gray">
                          {author.fullName}
                        </Typography>
                        <div className="5 flex items-center gap-0">
                        </div>
                      </div>
                      <Typography color="blue-gray">
                        {author.title}
                      </Typography>
                    </div>
                  </CardHeader>
                  <CardBody className="mb-6 p-0">
                    <Typography>
                      &quot;
                      {news.personalQuote}
                      &quot;
                    </Typography>
                  </CardBody>
                </Card>
              ) : (<p></p>)}
            </div>
            <div className="col-span-3">
              <div>
                <Typography variant="body1">{news.desc}</Typography>
              </div>
              <div style={{ paddingTop: "50px" }}>
                <Typography variant="body1">{news.content}</Typography>
              </div>
              {news.link_subject ? (
                <div style={{
                  paddingTop: "50px"
                }} className="flex w-max gap-4">
                  <Link to={news.link_subject}>
                    <Button variant="outlined">{news.link_subject_title}</Button>
                  </Link>
                </div>
              ) : (<p></p>)}
            </div>
          </div>
        </div>
      ) : (
        <p>Loading or not found</p>
      )}
    </div>
  );
};


export default NewsPage;