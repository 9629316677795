import React from 'react';
import { HorizontalCard } from './components/News';




function News () {
    return (
        <div>
            <HorizontalCard />
        </div>
    )
}

export default News;