import React from 'react';
import { PresentationHUB } from './components/Presentation';




function About () {
    return (
        <div>
            <PresentationHUB />
        </div>
    )
}

export default About;