import React from 'react';
import { ReglementIntérieur } from './components/RèglementIntérieur';



function Rules () {
    return (
        <div>
            <ReglementIntérieur />
        </div>
    )
}

export default Rules;