import React from "react";
import { CardWithLink } from "./components/Projects";
import { Typography } from "@material-tailwind/react";




function Projects() {
    return (
        <div style={{
            alignItems: "center",
            paddingLeft: "10%",
            paddingRight: "10%",
        }}>
            <div className="justify-center flex mt-20 mb-20" style={{ textAlign: "center" }}>
                <Typography variant="h2">Les Projets au H.U.B</Typography>
            </div>
            <div>
                <CardWithLink />
            </div>
        </div>
    )
}

export default Projects;