import React, { useEffect, useState } from "react";

import { Card, CardBody, CardFooter, Typography, Button, IconButton } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon, NewspaperIcon } from "@heroicons/react/24/outline";

import { Link } from "react-router-dom";

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';



export function HorizontalCard() {
    const [news, setNews] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [newsPerPage] = useState(9);

    useEffect(() => {
        const db = firebase.firestore();
        db.collection('news')
            .orderBy('eventDate', 'desc')
            .get()
            .then(async (querySnapshot) => {
                const data = await Promise.all(querySnapshot.docs.map(async (doc) => {
                    const event = doc.data();
                    return {
                        id: doc.id,
                        ...event,
                    };
                }));
                setNews(data);
            })
            .catch((error) => {
                console.error('Error retrieving events:', error);
            });
    }, []);

    const totalPages = Math.ceil(news.length / newsPerPage);

    const indexOfLastNews = currentPage * newsPerPage;
    const indexOfFirstNews = indexOfLastNews - newsPerPage;
    const currentNews = news.slice(indexOfFirstNews, indexOfLastNews);


    return (
        <div>
            <div className="grid gap-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3" style={{
                margin: 'auto',
                maxWidth: '1200px',
                padding: '0 15px',
                paddingTop: '30px',
            }}>
                {currentNews.map((data) => (
                    <div key={data.id}>
                        <Card className="h-full w-full">
                            <CardBody>
                                <NewspaperIcon className="h-12 w-12 text-gray-900" style={{
                                    margin: '3px',
                                }} />
                                <Typography variant="h6" color="gray" className="mb-4 uppercase">
                                    {data.eventDate && new Date(data.eventDate.toDate()).toLocaleDateString("fr-FR", {
                                        day: "numeric",
                                        month: "long",
                                        year: "numeric",
                                    })}
                                </Typography>
                                <Typography variant="h5" color="blue-gray" className="mb-2">
                                    {data.title}
                                </Typography>
                                <Typography>
                                    {data.desc}
                                </Typography>
                            </CardBody>
                            <CardFooter className="pt-0 justify-end">
                                <Link to={data.link} className="inline-block">
                                    <Button size="sm" variant="text" className="flex items-center gap-2">
                                        En savoir plus
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={2}
                                            stroke="currentColor"
                                            className="h-4 w-4"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                            />
                                        </svg>
                                    </Button>
                                </Link>
                            </CardFooter>
                        </Card>
                    </div>
                ))}
            </div>
            {/* Pagination */}
            <div className="flex items-center gap-8 justify-center mt-4">
                <IconButton
                    size="sm"
                    variant="full"
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
                </IconButton>
                <Typography color="gray" className="font-normal">
                    Page <strong className="text-gray-900">{currentPage}</strong> of{" "}
                    <strong className="text-gray-900">{totalPages}</strong>
                </Typography>
                <IconButton
                    size="sm"
                    variant="full"
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
                </IconButton>
            </div>
        </div>
    );
}
