import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Typography, Input, Select, Option } from "@material-tailwind/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Link } from 'react-router-dom';

export function CardWithLink() {
    const [activeProjects, setActiveProjects] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterType, setFilterType] = useState('');
    const [projectTypes, setProjectTypes] = useState([]);

    // Get the team members from the database
    useEffect(() => {
        const db = firebase.firestore();
        db.collection('projects')
            .get()
            .then(async (querySnapshot) => {
                const data = await Promise.all(querySnapshot.docs.map(async (doc) => {
                    const event = doc.data();
                    const storageRef = firebase.storage().ref();
                    try {
                        const imgRef = storageRef.child(event.img);
                        const imgUrl = await imgRef.getDownloadURL();
                        return {
                            ...event,
                            img: imgUrl,
                        };
                    } catch (error) {
                        console.error('Error fetching image URL:', error);
                        return null;
                    }
                }));
                const filteredData = data.filter((item) => item !== null);
                setActiveProjects(filteredData);
                setFilteredProjects(filteredData);

                // Extract unique project types
                const types = filteredData.map(project => project.type);
                const uniqueTypes = Array.from(new Set(types));
                setProjectTypes(uniqueTypes);
            })
            .catch((error) => {
                console.error('Error retrieving events:', error);
            });
    }, []);

    useEffect(() => {
        // Filter projects based on search term and type
        let filtered = activeProjects.filter(project => {
            return (
                project.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
                (filterType === '' || project.type === filterType)
            );
        });
        setFilteredProjects(filtered);
    }, [searchTerm, filterType, activeProjects]);
    
    

    return (
        <>
            <div className='grid gap-3 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4'>
                <div className="md:col-span-2 lg:col-span-3">
                    <Input icon={<MagnifyingGlassIcon className="h-5 w-5" />} 
                           label="Chercher un projet" 
                           onChange={(e) => setSearchTerm(e.target.value)} 
                           value={searchTerm} />
                </div>
                <div className="">
                    <div className="">
                        <Select
                            label="Filtrer par type"
                            value={filterType}
                            onChange={(val) => setFilterType(val)}
                        >
                            <Option value="">Tous les types</Option>
                            {projectTypes.map((type, index) => (
                                <Option key={index} value={type}>{type}</Option>
                            ))}
                        </Select>
                    </div>
                </div>
            </div>
            <hr className='mt-3 pt-3'/>
            <div className="grid gap-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                {filteredProjects.map((project) => (
                    <Link key={project.id} to={project.link} className="inline-block">
                        <Card className="w-full max-w-[48rem] flex-row">
                            <CardHeader shadow={false} floated={false} className="m-0 w-2/5 shrink-0 rounded-r-none">
                                <img src={project.img} alt="" className="h-full w-full object-cover" />
                            </CardHeader>
                            <CardBody>
                                <Typography variant="h6" color="gray" className="mb-4 uppercase">
                                    {project.type}
                                </Typography>
                                <Typography variant="h4" color="blue-gray" className="mb-2">
                                    {project.title}
                                </Typography>
                                <Typography color="gray" className="mb-8 font-normal">
                                    {project.desc}
                                </Typography>
                                <Typography color="gray">
                                    {project.datePublished && new Date(project.datePublished.toDate()).toLocaleDateString("fr-FR", {
                                        month: "long",
                                        year: "numeric",
                                    })}
                                </Typography>
                            </CardBody>
                        </Card>
                    </Link>
                ))}
            </div>
        </>
    );
}
